import './styles/layoutActuIndex.scss';

require('./js/video-pres');
require('./js/headerHolder');
require('./js/oktaForceMail');
require('./js/forgotPassword');
const loginFormContainer = '.login-form-container'
const toLoginBtn = '.to-login-btn'

//to show login popin in click in login-btn
$(document).on('click', '.to-login-btn', function (e) {
    if (isInMobile()) {
        e.stopPropagation();
        $(loginFormContainer).slideToggle('slow')
        $(toLoginBtn).hide()
    }
})

//to hide login popin in click in document
$(document).on('click', function () {
    if (isInMobile()) {
        $(loginFormContainer).slideUp('slow')
        $(toLoginBtn).show()
    }
});

//stop hide popin in click in login popin
$(document).on('click', loginFormContainer, function (e) {
    if (isInMobile()) {
        e.stopPropagation();
    }
})

//show login if has error
$(document).ready(function () {
    if ($('.error-wrapper').length > 0 && isInMobile()) {
        $(loginFormContainer).show()
        $(toLoginBtn).hide()
    }
})

function isInMobile() {
    return document.body.clientWidth < 1000
}

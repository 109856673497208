const $ = require("jquery");
let idElement = '#modalForgotPassword';
let idElementLogin = '#modalForgotPasswordLogin';
let idElementSuccess = '#modalForgotPasswordSuccess';
let url = '/forgotPassword';


function openModal(data, id)
{
    $(id).parent().remove();
    $('body').append(data.template);
    $(id).modal();
}

function cleanModal()
{
    $(idElement).modal('hide');
    $(idElement).parent().remove();
    $(idElementLogin).modal('hide');
    $(idElementLogin).parent().remove();
    $(idElementSuccess).modal('hide');
    $(idElementSuccess).parent().remove();
    $('.modal-backdrop ').remove();
}

$(document).on('submit', '#formForgotPassword, #btn_submit', function (e) {
    e.preventDefault();

    $.ajax({
        type: 'POST',
        url: $('#formForgotPassword').attr('action'),
        data: $(this).serialize(),
        success: function (data) {
            if (data.error && data.login) {
                $.ajax({
                    type: 'GET',
                    url: url + '/true/' + $('#forgot_password_email').val(),
                    success: function (data) {
                        cleanModal();
                        openModal(data, idElementLogin);
                    }
                });
            } else if (!data.error) {
                let getId = data.login ? idElementLogin : idElement;
                let reload = $(data.template).children(getId).children('.modal-dialog').html();
                $('.popin '+ getId +' .modal-dialog').html(reload);
            } else {
                cleanModal();
                openModal(data, idElementSuccess);
            }
        }
    });
});

$(document).on('click', '#forgotPassword', function (e) {
    e.preventDefault();

    $.ajax({
        type: 'GET',
        url: url,
        data: $("#formForgotPassword").serialize(),
        success: function (data) {
            openModal(data, idElement);
        }
    });
});
var vex = require('vex-js');
vex.registerPlugin(require('vex-dialog'));
import Plyr from 'plyr';

$( document ).ready(function() {
    vex.defaultOptions.className = 'vex-theme-plain';
    $( "#video-pres" ).click(function() {
        vex.dialog.open({
            input: $("#video-container").html(),
            buttons: [],
            showCloseButton: true,
            afterOpen: function () {
                Plyr.setup('#presentation-video');
            },
            afterClose: function () {
            }
        });

    });
    if (screen.width < 1000) {
        Plyr.setup('#presentation-video');
    }
});

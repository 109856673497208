let idElement = '#modalOktaForceMail';
let idElementForbidden = '#modalOktaLoginForbidden';

$(document).ready(function () {
    $(idElement).modal();
    $(idElementForbidden).modal();
});

$(document).on('click', ' .custom_close_modal', function (e) {
    $(idElement).modal('hide');
    $(idElementForbidden).modal('hide');
});
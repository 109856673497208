/*
 * Correspond à templates/headerHolder.html.twig (header)
 * Inclus par templates/layoutAutossimo.html.twig
 */
import {setCookie} from "./cookie";
import {switchPriceUpdate} from "./pubtech-main-v2";
$(function() {
    $('#popinDevisIdg').click(function(e){// popin de synchronisation code rep / code rep idg
        e.stopPropagation();
    });
    $(document).click(function(){
        $("#popinDevisIdg").hide();
    });
    $('#popinDevisIdg div.err').hide();// erreurs de la popin

    $('#devisIdg').click(function(e){// menu setting (profil) sous-menu alertes / id garage
        if ($(this).attr('data-coderepidg')) {// cas identifié (code rep idg existe)
            $.ajax({
                url: Routing.generate('autossimo_update_nb_devis'),
                type: 'POST',
                data: {
                    codeRepIdg: $(this).attr('data-coderepidg'),
                    nbDevis: -1//marquer les devis comme consultés
                },
                dataType: 'json',
                complete: function(r){// r contient le rowCount du UPDATE mais je redirige dans tous les cas (success ou error)
//                    window.open(IDG_HOST+'/api/connect?codrep='+($('a#devisIdg').attr('data-coderepidg'))+'&hashcode='+($('a#devisIdg').attr('data-hashedcoderepidg')));
                }
            });
            window.open(IDG_HOST);//sortie du ajax pour ne pas être bloquée
        }
        else {// non connecté: affichage de la popup de login
            e.stopPropagation();
            $('#popinDevisIdg').show();
            $('#popinDevisIdg+strong').removeClass('open');

            if ($('#popinDevisIdg').offset().left + $('#popinDevisIdg').outerWidth() > $(window).width()) {
                $('#popinDevisIdg').css('right', 0);
            }
        }
    });
    $('#nosync form input').focus(function(e){
        $('#popinDevisIdg div.err').hide();
    });
    $('#nosync form').submit(function(e){// formulaire de synchronisation
        e.preventDefault();
        if ($('input#inputEmail').val() && $('input#inputPassword').val()) {
            $.ajax({
                url: Routing.generate('autossimo_synchronize_compte_idg'),
                type: 'POST',
                data: {
                    login: $('input#inputEmail').val(),
                    password: $('input#inputPassword').val(),
                    _token: $('#idg_account__token').val()
                },
                dataType: 'json',
                success: function(r){//r.response contient un int si rowCount, ['errCode':int] si erreur traitée, ['error':string] si erreur non traitée
//                    var response = JSON.parse(r.response);
                    if (r.errCode) {
                        if ($('#err'+(r.errCode)).length) {
                            $('#err'+(r.errCode)).show();
                        }
                        else {
                            $('#err0').show();
                        }
                    }
                    else {//que les requêtes (update code idg et/ou nb devis) aient réussi ou non
                        $("#nosync").hide();
                        $("#sync").show();
                        $('#okDevisIdg').click(function(e){
                            $("#popinDevisIdg").hide();
                        });
                        $('#devisIdg').attr('data-coderepidg', r.codeRepIdg);
                        $('#devisIdg').attr('data-hashedcoderepidg', r.hashedCodeRepIdg);
                    }
                },
                error: function(errorData){
                    if (400 === errorData.status) {
                        $('#errors_container').text(JSON.parse(errorData.responseText).message)
                        $('#errors_container').show();
                    } else {
                        $('#err0').show();
                    }
                }
            });
        }
        else {
            $('#popinDevisIdg div#errConn').show();
        }
    });

    var hash = '#informatique-libertes';
    var path = location.href;
    if(path.indexOf(hash) > -1 && $('#clienterp').length > 0) {
        $("html, body").animate({
                scrollTop: $(hash).offset().top - 125},
            2000);
    }

    setScreenSizeCookie()
    $(window).resize(function () {
        setScreenSizeCookie()
    });
});

function setScreenSizeCookie() {
    setCookie('screenSize', window.screen.width, 1000000)
}

$(document).ready(function() {
    let result = document.cookie.match("priceMode=(.*?)(;|$)");
    const modePrice = $('#toggle_currency_mode:hidden:first');
    if (null !== result && modePrice.length > 0 && modePrice.prop('checked') !== result[1]) {
        result = result[1] === 'false' ? false : true;
        setTimeout(function() {
            modePrice.prop('checked', result);
            switchPriceUpdate();
        }, 2000);

    }
    // Remove link sheet tooltip after 10 seconds if displayed
    var $tooltip = $('.nav-holder .message .infobull .tooltiptext');
    if ($tooltip.is(':visible')) {
        setTimeout(() => {
            $tooltip.each(function() {
                $(this).remove();
            })
        }, 10000);
    }
});

centerHeadband();
$( window ).resize(function() {
    centerHeadband();
});
function centerHeadband() {
    var width = ((window.innerWidth - 1000) / 2).toString();
    $('#left').css({'width': width.concat('px'), 'margin-left' : '-7px'});
    $('#right').css({'width': width.concat('px'), 'margin-right' : '-7px'});
    $('#left, #right').removeClass('hidden');
}
